import {
  Decision,
  DecisionReason,
  DEFAULT_ON_READY_TIMEOUT,
  EventProcessor,
  FeatureFlagDecision,
  HackleEvent,
  HackleInternalClient,
  Logger,
  User,
  VariationKey,
  WorkspaceFetcher
} from "@hackler/sdk-core"
import { EventEmitter } from "events"
import HackleClient, { PageView } from "./HackleClient"
import { HackleUserResolver } from "./identifier/HackleUserResolver"

const log = Logger.log

export default class HackleClientImpl implements HackleClient {
  private hackleInternalClient: HackleInternalClient
  private hackleUserResolver: HackleUserResolver

  constructor(workspaceFetcher: WorkspaceFetcher, eventProcessor: EventProcessor, eventEmitter: EventEmitter) {
    this.hackleInternalClient = new HackleInternalClient(workspaceFetcher, eventProcessor, eventEmitter)
    this.hackleUserResolver = new HackleUserResolver()
  }

  variation(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): string {
    return this.variationDetail(experimentKey, user, defaultVariation).variation
  }

  variationDetail(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): Decision {
    const defaultVariationKey = defaultVariation || "A"
    try {
      const hackleUser = this.hackleUserResolver.resolveOrNull(user)
      if (!hackleUser) {
        return Decision.of(defaultVariationKey, DecisionReason.INVALID_INPUT)
      }
      return this.hackleInternalClient._experiment(experimentKey, hackleUser, defaultVariationKey)
    } catch (e) {
      log.error(
        `Unexpected exception while deciding variation for experiment[${experimentKey}]. Returning default variation[${defaultVariationKey}] : ${e}`
      )
      return Decision.of(defaultVariationKey, DecisionReason.EXCEPTION)
    }
  }

  isFeatureOn(featureKey: number, user?: User | string): boolean {
    return this.featureFlagDetail(featureKey, user).isOn
  }

  featureFlagDetail(featureKey: number, user?: User | string): FeatureFlagDecision {
    try {
      const hackleUser = this.hackleUserResolver.resolveOrNull(user)
      if (!hackleUser) {
        return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
      }
      return this.hackleInternalClient._featureFlag(featureKey, hackleUser)
    } catch (e) {
      log.error(
        `"Unexpected exception while deciding feature flag[${featureKey}]. Returning default value[false] : ${e}`
      )
      return FeatureFlagDecision.off(DecisionReason.EXCEPTION)
    }
  }

  track(event: HackleEvent | string, user?: User | string) {
    log.debug(`track event : ${JSON.stringify(event)}`)
    const hackleEvent = this._convertEvent(event)
    const hackleUser = this.hackleUserResolver.resolveOrNull(user)
    if (!hackleUser) {
      return
    }
    this.hackleInternalClient._track(hackleEvent, hackleUser)
  }

  trackPageView(option?: PageView): void {
    log.debug("tracking page view")
    const hackleEvent = { key: "$page_view" }
    const hackleUser = this.hackleUserResolver.resolveOrNull(option?.user)
    if (!hackleUser) {
      return
    }
    this.hackleInternalClient._track(hackleEvent, hackleUser)
  }

  onReady(block: () => void, timeout: number = DEFAULT_ON_READY_TIMEOUT): void {
    this.hackleInternalClient._onReady(block, timeout)
  }

  onInitialized(config?: { timeout?: number }): Promise<{ success: boolean }> {
    return this.hackleInternalClient._onInitialized({ timeout: config?.timeout })
  }

  close(): void {
    log.debug("Hackle Client is closing")
    this.hackleInternalClient._close()
  }

  private _convertEvent(event: HackleEvent | string): HackleEvent {
    if (typeof event === "string") {
      return { key: event }
    }
    return event
  }
}
