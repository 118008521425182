import { EventProcessor, Logger, logLevels, User, HackleEvent } from "@hackler/sdk-core"
import EventDispatcherImpl from "./EventDispatcherImpl"
import { getRequest } from "./HttpClient"
import { EventEmitter } from "events"
import {
  BATCH_SIZE,
  DEFAULT_FLUSH_INTERVAL,
  EVENT_DISPATCH_URL,
  EVENT_DISPATCH_URL_POSTFIX,
  EVENT_DISPATCH_URL_PREFIX,
  SDK_NAME_HEADER,
  SDK_VERSION,
  SDK_VERSION_HEADER,
  WORKSPACE_FETCH_URL_POSTFIX,
  WORKSPACE_FETCH_URL_PREFIX
} from "./config"
import HackleClient, { PageView } from "./HackleClient"
import HackleClientImpl from "./HackleClientImpl"
import IdentifierManager, { getUserId, removeUserId, setUserId } from "./identifier/IdentifierManager"
import PollingWorkspaceFetcher from "./workspace/PollingWorkspaceFetcher"

const log = Logger.log

let hackleClientCache: HackleClient | null = null

interface Config {
  debug?: boolean,
  auto_track_page_view?: boolean,

  [key: string]: string | boolean | undefined
}

const defaultConfig: Config = {
  debug: false,
  log_disabled: false,
  auto_track_page_view: true,
  SDK_NAME_HEADER: "js-client-sdk",
  SDK_VERSION_HEADER: SDK_VERSION
}

function createInstance(sdkKey: string, _config?: Config): HackleClient {
  const config: Config = {
    ...defaultConfig,
    ..._config
  }

  if (config.log_disabled) {
    Logger.setLogLevel(logLevels.DISABLE)
  } else {
    if (config.debug) {
      Logger.setLogLevel(logLevels.DEBUG)
    }
  }

  log.debug("sdkKey : " + sdkKey)
  if (!sdkKey) {
    log.error("SDK Key must not be null")
  }

  if (hackleClientCache) {
    log.debug("use already exists hackleClient")
    return hackleClientCache
  }

  IdentifierManager.initialize()

  let useBeacon = false

  if ((typeof window) !== "undefined") {
    // @ts-ignore
    useBeacon = window && window.navigator && window.navigator.sendBeacon && true
    if (useBeacon) {
      log.debug("support sendBeacon API")
    }
  }

  const eventDispatcher = new EventDispatcherImpl(sdkKey, {
    dispatchUrl: EVENT_DISPATCH_URL,
    beaconDispatchUrl: `${EVENT_DISPATCH_URL_PREFIX}${sdkKey}${EVENT_DISPATCH_URL_POSTFIX}`,
    useBeacon: useBeacon,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })
  const eventEmitter = new EventEmitter()
  const workspaceFetcher = new PollingWorkspaceFetcher(sdkKey, getRequest, eventEmitter, {
    fetchUrl: `${WORKSPACE_FETCH_URL_PREFIX}${sdkKey}${WORKSPACE_FETCH_URL_POSTFIX}`,
    updateInterval: -1,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })

  const eventProcessor = new EventProcessor(eventDispatcher, BATCH_SIZE, DEFAULT_FLUSH_INTERVAL)
  const hackleClient = new HackleClientImpl(workspaceFetcher, eventProcessor, eventEmitter)

  hackleClientCache = hackleClient

  if (config.auto_track_page_view) {
    const pageView = () => hackleClient.trackPageView({
      user: {
        id: getUserId()
      }
    })
    hackleClient.onReady(() => {
      pageView()


      function customEvent ( type: string ): Event {
        if (typeof window.Event === "function") return new Event(type)

        const params = { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( type, params.bubbles, params.cancelable, params.detail );
        return evt;
      }

      try {
        history.pushState = (f => function pushState() {
          try {
            // @ts-ignore
            var ret = f.apply(this, arguments)
            window.dispatchEvent(customEvent("locationchange"))
            return ret
          } catch (e) {
            log.error(e)
          }
        })(history.pushState)

        history.replaceState = (f => function replaceState() {
          try {
            // @ts-ignore
            var ret = f.apply(this, arguments)
            window.dispatchEvent(customEvent("locationchange"))
            return ret
          } catch (e) {
            log.error(e)
          }
        })(history.replaceState)

        window.addEventListener("popstate", () => {
          try {
            window.dispatchEvent(customEvent("locationchange"))
          } catch (e) {
            log.error(e)
          }
        })
        window.addEventListener("locationchange", (e) => {
          try {
            pageView()
          } catch(e) {
            log.error(e)
          }
        })
      } catch (e) {
        log.error(e)
      }
    })
  }

  const flush = () => {
    removeUserId()
    hackleClient.close()
  }

  window.addEventListener("onpagehide" in window ? "pagehide" : "unload", flush)

  return hackleClient
}

export default {
  createInstance,
  getUserId,
  setUserId,
  removeUserId,
}

export { createInstance }
export { getUserId }
export { setUserId }
export { removeUserId }
export { HackleClient }
export { User }
export { HackleEvent }
export { PageView }

import "core-js/features/promise"
import "core-js/features/array"
