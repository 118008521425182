import { HackleUser, IdentifiersBuilder, IdentifierType, Properties, User } from "@hackler/sdk-core"
import { getUserId } from "./IdentifierManager"
import HacklePropertyGenerator from "../property/HacklePropertyGenerator"

export class HackleUserResolver {

  resolveOrNull(input?: User | string): HackleUser | undefined {
    const user = this._resolveUser(input)
    return this._resolveHackleUserOrNull(user)
  }

  _resolveUser(input?: User | string): User {
    if (!input) {
      return { id: getUserId() }
    }

    if (typeof input === "string") {
      return { id: input }
    }

    return input
  }

  _resolveHackleUserOrNull(user: User): HackleUser | undefined {
    const builder = new IdentifiersBuilder()
    builder.addIdentifiers(user.identifiers || {})
    if (user.id) {
      builder.add(IdentifierType.ID, user.id)
    }
    if (user.userId) {
      builder.add(IdentifierType.USER, user.userId)
    }
    builder.add(IdentifierType.DEVICE, user.deviceId || getUserId())
    const identifiers = builder.build()

    if (Object.keys(identifiers).length === 0) {
      return undefined
    }

    return {
      identifiers: identifiers,
      properties: user.properties || {},
      hackleProperties: this._getHackleProperties()
    }
  }

  _getHackleProperties(): Properties {
    let hackleProperties
    // @ts-ignore
    if (typeof window !== "undefined") {
      // @ts-ignore
      hackleProperties = HacklePropertyGenerator.generate(window)
    }
    return hackleProperties || {}
  }
}